import React, { Component, Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Row from '../components/Row'
import Div from '../components/Div'
import Column from '../components/Column'
import Body2 from '../components/Body2'

import GrupoParvi from '../images/clients/parvi.png'
import CBA from '../images/clients/logo-cba.png'
import FarmaciaRoval from '../images/clients/farmacia-roval.png'

import { 
  ctaText,
  video
} from '../components/styles/Clients.styles'

const DepoimentosVideosItem = ({
  videoLink,
  logo,
  text,
  author,
  authorDesc
}) => {
  return (
    <Row
      mt={[30, 50]}
      style={{
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 12
      }}
    >
      <Div
        flexDirection={['column', 'column', 'row']}
        maxWidth={['100%', 422, 680, 1032]}
        style={{
          margin: '0 auto',
          textAlign: 'center',
          padding: 0,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 12
        }}
      >
        <Column
          width={[1, 1, 1/2]}
          px={null}
        >
          <Div
            maxWidth={['100%', 422, 680, 813]}
          >
            <iframe css={video} width="100%" height="415" src={videoLink} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
          </Div>
        </Column>
        <Column
          px={null}
          width={[1, 1, 1/2]}
          pt={[40, 40, 0, 0]}
          pb={[40, 40, 0, 0]}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src={logo} 
            style={{
              width: 150,
              marginBottom: 24
            }} 
          />
          <Body2
            style={{
              width: 328,
              color: 'rgba(0, 0, 0, 0.87)',
              textAlign: 'center',
              marginBottom: 24
            }}
          >
            {text}
          </Body2>
          <div css={ctaText}>
            {author}
          </div>
          <div css={ctaText}>
            {authorDesc}
          </div>
        </Column>
      </Div>
    </Row>
  )
}

export default class SimpleSlider extends Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      outline: 'none',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    }
    return (
      <Fragment>
        <div style={{ marginBottom: 80 }}>
          <Slider {...settings}>
            <DepoimentosVideosItem
              videoLink={'https://www.youtube.com/embed/INRebZX4_PI'}
              text={'“A gente conseguiu, na HostDime, latências incríveis, o que fez com que a gente pudesse escalar nosso negócio e dar foco pra o que realmente é o nosso negócio. Não somos uma empresa de tecnologia, mas sim uma empresa de saúde.”'}
              author={'Christian Paiva'}
              authorDesc={'Gerente de TI da Farmácia Roval'}
              logo={FarmaciaRoval}
            />
            <DepoimentosVideosItem
              videoLink={'https://www.youtube.com/embed/RE7tZMhFtuw'}
              text={'“O diferencial da HostDIme não é por que ela é apenas um Data Center. É por que ela entende o negócio da CBA.”'}
              author={'Adriano Gomper'}
              authorDesc={'CEO da CBA Tech'}
              logo={CBA}
            />
            <DepoimentosVideosItem
              videoLink={'https://www.youtube.com/embed/SjDTEr1jrto'}
              text={'“Além da infraestrutura e da disponibilidade de recursos, a latência foi um ponto crucial para que nós fechássemos essa parceria.”'}
              author={'Daniel Albuquerque'}
              authorDesc={'Diretor de TI do Grupo Parvi'}
              logo={GrupoParvi}
            />
          </Slider>
        </div>
      </Fragment>
    )
  }
}