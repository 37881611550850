import React, { Component, Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Div from '../components/Div'
import Body2 from '../components/Body2'
import { css } from '@emotion/core'
import { mq } from '../components/styles/Layout.styles'
import ds from '../theme'
import H6 from './../components/H6'

import ClubeTurismo from '../images/clients/clube-turismo.jpg'
import MistGames from '../images/clients/mist-games.png'
import ServHost from '../images/clients/servhost.png'

import { 
  ctaText,
} from '../components/styles/Clients.styles'

const DepoimentosParceirosItem = ({
  title,
  text,
  officeName,
  officePost,
  logo
}) => {
  return (
    <Div
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        maxWidth: 328,
        margin: '0 auto'
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
        style={{
          padding: '50px 40px',
        }}
      >
        <div>
          <img src={logo} 
            style={{ 
              width: 139,
              borderRadius: '50%', 
              marginBottom: 24 
            }} 
          />
        </div>
        <H6
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 24
          }}
        >
          {title}
        </H6>
        <Body2 
          style={{ 
            marginBottom: 24, 
            minHeight: 144,
            textAlign: 'center',
            lineHeight: 1.71,
            color: 'rgba(0, 0, 0, 0.87)'
          }}>
          {text}
        </Body2>
        <div css={ctaText}>
          {officeName}
        </div>
        <div css={ctaText}>
          {officePost}
        </div>
      </Div>
    </Div>
  )
}

export default class SimpleSlider extends Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      outline: 'none',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    }
    return (
      <Fragment>
        <Slider {...settings}>
          <DepoimentosParceirosItem
            title={'Clube Turismo'}
            text={'"A parceria com a HostDime foi um marco para a rede Clube Turismo. Somos imensamente gratos pela parceria e pelo suporte excepcional.”'}
            officeName={'Vitor Fernandes'}
            officePost={'IT Manager e Web Developer'}
            logo={ClubeTurismo}
          />
          <DepoimentosParceirosItem
            title={'Mist Games'}
            text={'“A HostDime apareceu como uma grande solução, começando pela qualidade e eficiência no atendimento do setor comercial que se estende para demais áreas.”'}
            officeName={'Bruno Rogrigues'}
            officePost={'Diretor Operacional'}
            logo={MistGames}
          />
          <DepoimentosParceirosItem
            title={'ServHost'}
            text={'"A infraestrutura disponibilizada pela Hostdime nos dá muita tranquilidade e faz com que tenhamos total condições de oferecer os melhores serviços aos nossos clientes.”'}
            officeName={'Leonardo Eliodoro'}
            officePost={'Diretor de finanças'}
            logo={ServHost}
          />
        </Slider>
      </Fragment>
    )
  }
}