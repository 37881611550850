import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ds from '../theme'
import { useMediaQuery } from 'react-responsive'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from './../components/Div'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H6 from './../components/H6'
import { css } from '@emotion/core'
import DepoimentosVideos from '../components/DepoimentosVideos'
import DepoimentosParceiros from '../components/DepoimentosParceiros'

import { Container, mq } from '../components/styles/Layout.styles'
import { btnMedium_ } from '../components/styles/OutlineButton.styles'
import { 
  styledClients,
  styleTestimony,
  header
} from '../components/styles/Clients.styles'

import Parvi from '../images/clients/grupo-parvi.jpg'
import Aiam from '../images/clients/aiam.png'
import Basis from '../images/clients/basis.jpg'
import BateriasMoura from '../images/clients/baterias-moura.png'
import Bentonisa from '../images/clients/bentonisa.jpg'
import Brasimpex from '../images/clients/brasimpex .jpg'
import Britania from '../images/clients/britania.png'
import CamaraJp from '../images/clients/camara-jp.png'
import CartoriaAzevedoBastos from '../images/clients/cartorio-azevedo-bastos.png'
import Casatudo from '../images/clients/casatudo.png'
import Cbatech from '../images/clients/cbatech.png'
import ChilliBeans from '../images/clients/chilli-beans.png'
import CienciasMedicasPb from '../images/clients/ciencias-medicas-pb.png'
import ClubeTurismo from '../images/clients/clube-turismo.jpg'
import CoifeOdonto from '../images/clients/coife-odonto.png'
import ColengioAntares from '../images/clients/colegio-antares.jpg'
import Conductor from '../images/clients/conductor.png'
import Contreina from '../images/clients/contreina .jpg'
import DbDireito from '../images/clients/db-direto.jpg'
import DiretrixOn from '../images/clients/diretrix-on.jpg'
import Ecologica from '../images/clients/ecologica.png'
import EGen from '../images/clients/e-gen.jpg'
import EleveHost from '../images/clients/eleva-host.png'
import Elizabeth from '../images/clients/elizabeth.png'
import Equilibrio from '../images/clients/equilibrio.png'
import FaculdadeCescage from '../images/clients/faculdade-cescage.jpg'
import FaculdadeFutura from '../images/clients/faculdade-futura.jpg'
import FaculdadeSouza from '../images/clients/faculdade-souza.png'
import FaculdadeUnicampo from '../images/clients/faculdade-unicampo.jpg'
import Fan from '../images/clients/fan.png'
import Fps from '../images/clients/fps.png'
import GrupoAssesi from '../images/clients/grupo-assesi.jpg'
import GrupoHorizonteOriginal from '../images/clients/grupo-horizonte-original.png'
import HospitalMemorialSF from '../images/clients/hospital-memorial-sao-francisco.png'
import HospitalRegionalDeFranca from '../images/clients/hospital-regional-de-franca.jpg'
import Iland from '../images/clients/iland.png'
import InstitutoAdvogados from '../images/clients/instituto-advogados.png'
import Jotur from '../images/clients/jotur.jpg'
import K2Host from '../images/clients/k2host.png'
import KurierTec from '../images/clients/kurier-tecnologia.png'
import LinkSolution from '../images/clients/link-solutions.jpg'
import MarcosInacio from '../images/clients/marcos_inacio.png'
import Melita from '../images/clients/melita.png'
import MemorialDiagnostico from '../images/clients/memorial-diagnostico.png'
import NovaVidaTi from '../images/clients/nova-vida-ti.jpg'
import OAB from '../images/clients/oab.png'
import OABPe from '../images/clients/oab_pe.png'
import OmegaSistemas from '../images/clients/omega-sistemas.png'
import ReiAlimentos from '../images/clients/rei-alimentos.jpg'
import RogerioMenezesLeiloiro from '../images/clients/rogerio-menezes-leiloeiro.jpg'
import Roval from '../images/clients/roval.png'
import Sebrae from '../images/clients/sebrae.png'
import ServHost from '../images/clients/servhost.png'
import SescPb from '../images/clients/sesc-paraiba.png'
import Sintese from '../images/clients/sintese.png'
import SociedadeBrDeCbm from '../images/clients/socieade-brasileira-de-cbm.jpg'
import StaloDigital from '../images/clients/stalo-digital.jpg'
import SuporteOne from '../images/clients/suporte-one.jpg'
import TomoVale from '../images/clients/tomovale.jpg'
import Unifarma from '../images/clients/unifamma.png'
import Selfit from '../images/clients/selfit.png'
import Agemar from '../images/clients/agemar.png'
import DiasGomes from '../images/clients/dg-logo.png'
import EstaleiroAtlantico from '../images/clients/logo-sa.png'
import RedeMais from '../images/clients/rede-mais.png'
import Vsoft from '../images/clients/vsoft.png'
import Uniesp from '../images/clients/uniesp.png'
import Unifacisa from '../images/clients/unifacisa.png'
import Intelbras from '../images/clients/intelbras.png'
import Medic from '../images/clients/4Medic.png'
import CBMPB from '../images/clients/CBMPB.png'
import PMPB from '../images/clients/PMPB.png'
import RCContabilidade from '../images/clients/RC_Contabilidade.png'
import UniodontoRN from '../images/clients/UniodontoRN.png'
import ViaSul from '../images/clients/Via_Sul.png'
import Vortex from '../images/clients/Vortex.png'
import WiseDB from '../images/clients/WiseDB.png'
import Epasa from '../images/clients/epasa.png'
import MouraDubex from '../images/clients/moura-dubeux.png'
import Pedragon from '../images/clients/pedragon.png'
import FESP from '../images/clients/FESP_Faculdades.png'
import UNIMED from '../images/clients/unimed-jp.png'
import MoradaPaz from '../images/clients/moradadapaz.png'
import AVIL from '../images/clients/avil.png'
import Tropical from '../images/clients/logo-tropical.png'
import LogoSael from '../images/clients/sael.png'
import FeitosaNovais from '../images/clients/feitosa-e-novais.png'
import IrmaosVila from '../images/clients/irmaos_vila.png'
import Fir from '../images/clients/FIR.png'

const clients = [
  {
    name: 'Irmãos Vila',
    logo: IrmaosVila
  },
  {
    name: 'FIR',
    logo: Fir
  },
  {
    name: 'Tropical Supermercados',
    logo: Tropical
  },
  {
    name: 'Sael',
    logo: LogoSael
  },
  {
    name: 'Feitosa e Novais Advogados Associados',
    logo: FeitosaNovais
  },
  {
    name: 'Unimed João Pessoa',
    logo: UNIMED
  },
  {
    name: 'Morada da Paz',
    logo: MoradaPaz
  },
  {
    name: 'Avil',
    logo: AVIL
  },
  {
    name: 'FESP Faculdades',
    logo: FESP
  },
  {
    name: 'Pedragon',
    logo: Pedragon
  },
  {
    name: 'Epasa',
    logo: Epasa
  },
  {
    name: 'Moura Dubex',
    logo: MouraDubex
  },
  {
    name: 'Unifacisa',
    logo: Medic
  },
  {
    name: 'CBMPB',
    logo: CBMPB
  },
  {
    name: 'PMPB',
    logo: PMPB
  },
  {
    name: 'RC Contabilidade',
    logo: RCContabilidade
  },
  {
    name: 'UniodontoRN',
    logo: UniodontoRN
  },
  {
    name: 'ViaSul',
    logo: ViaSul
  },
  {
    name: 'Vortex',
    logo: Vortex
  },
  {
    name: 'WiseDB',
    logo: WiseDB
  },
  {
    name: 'Unifacisa',
    logo: Unifacisa
  },
  {
    name: 'Intelbras',
    logo: Intelbras
  },
  {
    name: 'RedeMais',
    logo: RedeMais
  },
  {
    name: 'Grupo Parvi',
    logo: Vsoft
  },
  {
    name: 'Grupo Parvi',
    logo: Uniesp
  },
  {
    name: 'Grupo Parvi',
    logo: DiasGomes
  },
  {
    name: 'Grupo Parvi',
    logo: EstaleiroAtlantico
  },
  {
    name: 'Grupo Parvi',
    logo: Agemar
  },
  {
    name: 'Grupo Parvi',
    logo: Selfit
  },
  {
    name: 'Grupo Parvi',
    logo: Parvi
  },
  {
    name: 'Grupo Parvi',
    logo: Aiam
  },
  {
    name: 'Grupo Parvi',
    logo: Basis
  },
  {
    name: 'Grupo Parvi',
    logo: BateriasMoura
  },
  {
    name: 'Grupo Parvi',
    logo: Bentonisa
  },
  {
    name: 'Grupo Parvi',
    logo: Brasimpex
  },
  {
    name: 'Grupo Parvi',
    logo: Britania
  },
  {
    name: 'Grupo Parvi',
    logo: CamaraJp
  },
  {
    name: 'Grupo Parvi',
    logo: CartoriaAzevedoBastos
  },
  {
    name: 'Grupo Parvi',
    logo: Casatudo
  },
  {
    name: 'Grupo Parvi',
    logo: Cbatech
  },
  {
    name: 'Grupo Parvi',
    logo: ChilliBeans
  },
  {
    name: 'Grupo Parvi',
    logo: CienciasMedicasPb
  },
  {
    name: 'Grupo Parvi',
    logo: ClubeTurismo
  },
  {
    name: 'Grupo Parvi',
    logo: CoifeOdonto
  },
  {
    name: 'Grupo Parvi',
    logo: ColengioAntares
  },
  {
    name: 'Grupo Parvi',
    logo: Conductor
  },
  {
    name: 'Grupo Parvi',
    logo: Contreina
  },
  {
    name: 'Grupo Parvi',
    logo: DbDireito
  },
  {
    name: 'Grupo Parvi',
    logo: DiretrixOn
  },
  {
    name: 'Grupo Parvi',
    logo: Ecologica
  },
  {
    name: 'Grupo Parvi',
    logo: EGen
  },
  {
    name: 'Grupo Parvi',
    logo: EleveHost
  },
  {
    name: 'Grupo Parvi',
    logo: Elizabeth
  },
  {
    name: 'Grupo Parvi',
    logo: Equilibrio
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeCescage
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeFutura
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeSouza
  },
  {
    name: 'Grupo Parvi',
    logo: FaculdadeUnicampo
  },
  {
    name: 'Grupo Parvi',
    logo: Fan
  },
  {
    name: 'Grupo Parvi',
    logo: Fps
  },
  {
    name: 'Grupo Parvi',
    logo: GrupoAssesi
  },
  {
    name: 'Grupo Parvi',
    logo: GrupoHorizonteOriginal
  },
  {
    name: 'Grupo Parvi',
    logo: HospitalMemorialSF
  },
  {
    name: 'Grupo Parvi',
    logo: HospitalRegionalDeFranca
  },
  {
    name: 'Grupo Parvi',
    logo: Iland
  },
  {
    name: 'Grupo Parvi',
    logo: InstitutoAdvogados
  },
  {
    name: 'Grupo Parvi',
    logo: Jotur
  },
  {
    name: 'Grupo Parvi',
    logo: K2Host
  },
  {
    name: 'Grupo Parvi',
    logo: KurierTec
  },
  {
    name: 'Grupo Parvi',
    logo: LinkSolution
  },
  {
    name: 'Grupo Parvi',
    logo: MarcosInacio
  },
  {
    name: 'Grupo Parvi',
    logo: Melita
  },
  {
    name: 'Grupo Parvi',
    logo: MemorialDiagnostico
  },
  {
    name: 'Grupo Parvi',
    logo: NovaVidaTi
  },
  {
    name: 'Grupo Parvi',
    logo: OAB
  },
  {
    name: 'Grupo Parvi',
    logo: OABPe
  },
  {
    name: 'Grupo Parvi',
    logo: OmegaSistemas
  },
  {
    name: 'Grupo Parvi',
    logo: ReiAlimentos
  },
  {
    name: 'Grupo Parvi',
    logo: RogerioMenezesLeiloiro
  },
  {
    name: 'Grupo Parvi',
    logo: Roval
  },
  {
    name: 'Grupo Parvi',
    logo: Sebrae
  },
  {
    name: 'Grupo Parvi',
    logo: ServHost
  },
  {
    name: 'Grupo Parvi',
    logo: SescPb
  },
  {
    name: 'Grupo Parvi',
    logo: Sintese
  },
  {
    name: 'Grupo Parvi',
    logo: SociedadeBrDeCbm
  },
  {
    name: 'Grupo Parvi',
    logo: StaloDigital
  },
  {
    name: 'Grupo Parvi',
    logo: SuporteOne
  },
  {
    name: 'Grupo Parvi',
    logo: TomoVale
  },
  {
    name: 'Grupo Parvi',
    logo: Unifarma
  }
]

const fistChildMargin = css(
  mq({
    '& > div:first-child': {
      marginRight: [16, 16, 20]
    }
  })
)

const ClientsList = ({
  limit,
  list
}) => {
  const totalRows = Math.ceil(list.length / limit)
  const [rowCount, setRowCount] = useState(4)
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 840px)' })

  const responsiveCount = isMobileOrTablet
    ? rowCount - 2
    : rowCount

  const showMore = () => {
    if (responsiveCount < totalRows) {
      setRowCount(rowCount + 1)
    }
  }

  return (
    <>
      {
        Array(responsiveCount).fill(0).map((row, i) => {
          const min = (i * limit)
          const max = (i * limit) + limit
          const range = list.slice(min, max)
          const half = range.slice(0, Math.ceil(range.length / 2))
          const otherHalf = range.slice(Math.ceil(range.length / 2))
          return (
            <Fragment
              key={`clientrow-${i}`}
            >
              <Row
                flexDirection={['column', 'column', 'row']}
              >
                <Div
                  maxWidth={['100%', 328, 680, 700]}
                  style={{
                    margin: '0 auto',
                    textAlign: 'center',
                    flexDirection: 'inherit'
                  }}
                >
                  <Column
                    width={[1, 1, 1/2, 1/2]}
                    mb={16}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                    css={fistChildMargin}
                  >
                    {half.map((client, y) => (
                      <Fragment
                        key={`listitem-${i}-${y}`}
                      >
                        {client.logo
                          ? (
                            <div
                              style={{
                                width: 156,
                                height: 156
                              }}
                            >
                              <img
                                src={client.logo}
                                alt={client.name}
                                style={{
                                  objectFit: 'cover',
                                  width: 156,
                                  height: 156,
                                  lineHeight: 1
                                }}
                              />
                            </div>
                          )
                          : (
                            <div
                              style={{
                                width: 156,
                                height: 156,
                                backgroundColor: ds.brand('iceBlue')
                              }}
                            />
                          )
                        }
                      </Fragment>
                    ))}
                  </Column>
                  {otherHalf.length > 0 && (
                    <Column
                      width={[1, 1, 1/2, 1/2]}
                      mb={16}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                      css={fistChildMargin}
                    >
                      {otherHalf.map((client, y) => (
                        <Fragment
                          key={`listitemb-${i}-${y}`}
                        >
                          {client.logo
                            ? (
                              <div
                                style={{
                                  width: 156,
                                  height: 156
                                }}
                              >
                                <img
                                  src={client.logo}
                                  alt={client.name}
                                  style={{
                                    objectFit: 'cover',
                                    width: 156,
                                    height: 156,
                                    lineHeight: 1
                                  }}
                                />
                              </div>
                            )
                            : (
                              <div
                                style={{
                                  width: 156,
                                  height: 156,
                                  backgroundColor: ds.brand('iceBlue')
                                }}
                              />
                            )
                          }
                        </Fragment>
                      ))}
                    </Column>
                  )}
                </Div>
              </Row>
            </Fragment>
          )})
      }
      {responsiveCount < totalRows && (
        <div
          css={btnMedium_}
          onClick={showMore}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            margin: '14px auto 0'
          }}
        >
          carregar mais
        </div>
      )}
    </>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledClients}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H3>Confiança e Parceria</H3>
              <H6>Essa é a forma HostDime <br />de atender bem nossos clientes.</H6>
            </Column>
          </Row>
          <DepoimentosVideos />
        </Container>
      </section>
      <section css={styleTestimony}>
        <Div 
          maxWidth={['100%', 422, 680, 1032]}
          style={{ 
            margin: '0 auto'
           }}
        >
          <DepoimentosParceiros />
        </Div>
      </section>
      <section>
        <Container
          style={{
            marginTop: 50,
            marginBottom: 100
          }}
        >
          <Row
            justifyContent={'center'}
            mt={50}
            mb={50}
          >
            <Div
              maxWidth={['100%', '100%', 680, 680]}
            >
              <H4
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  textAlign: 'center'
                }}
              >
                Mais de 50 mil organizações em todo o mundo confiam na HostDime.
              </H4>
            </Div>
          </Row>
          <ClientsList
            list={clients}
            limit={4}
          />
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Nossos clientes | HostDime Brasil'
        description='Mais de 50 mil organizações em todo o mundo confiam na gente. Confira alguns cases de sucesso e faça parte desse time também!'
      />
      <PageTemplate
        title='Confiança e Parceria'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
